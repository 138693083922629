// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#food-card{
    margin-top: 10px;
    padding-top: 5px;
}

.card-boarder{
    border: 3px solid skyblue;
}

.card-boarder:hover{
    border: 3px solid blueviolet;
    box-shadow: 10px 10px lightgray;
}`, "",{"version":3,"sources":["webpack://./src/Components/FoodCard/FoodCard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,+BAA+B;AACnC","sourcesContent":["#food-card{\n    margin-top: 10px;\n    padding-top: 5px;\n}\n\n.card-boarder{\n    border: 3px solid skyblue;\n}\n\n.card-boarder:hover{\n    border: 3px solid blueviolet;\n    box-shadow: 10px 10px lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
