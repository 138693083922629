import React from "react";
import './Footer.css';

function Footer(){
    return(
        <>
            <div className="Row col-sm-12 Footer">
                <div className="Footer_contents">
                    <p> Copyrights ©️ Arjuna Thennakoon 🌎  </p>
                </div>
            </div>
        </>
    )
}

export default Footer;