import React from "react";
import NavHeader from "../NavHeader/NavHeader";

function FoodInfoPage(){
    return(
        <>
            <NavHeader />
            <h2>This is sample food info</h2>
            <p>food details will go here</p>
        </>
    )
}

export default FoodInfoPage;