// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer{
    /* position : absolute; */
    /* bottom : 0; */
    height : 25px;
    margin-top : 40px;
    align-content: center;
    background-color: rgba(53, 49, 159, 0.9);
    color: rgba(0, 0, 0, 0.8);

    background-image: none;
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0% 0%;

    position: fixed;
    bottom: 0pt;
    left: 0pt;
    
}

.Footer_contents {

    height:40px; 
    width: 1000px; 
    margin:auto;

}

/* .footer {

    height:24px; // Replace with the height your footer should be
    width: 100%; // Don't change
    background-image: none;
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    position: fixed;
    bottom: 0pt;
    left: 0pt;

}    */

/* .footer_contents {

    height:24px; // Replace with the height your footer should be
    width: 1000px; // Visible width of footer
    margin:auto;

} */`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,wCAAwC;IACxC,yBAAyB;;IAEzB,sBAAsB;IACtB,yBAAyB;IACzB,6BAA6B;IAC7B,0BAA0B;;IAE1B,eAAe;IACf,WAAW;IACX,SAAS;;AAEb;;AAEA;;IAEI,WAAW;IACX,aAAa;IACb,WAAW;;AAEf;;AAEA;;;;;;;;;;;;MAYM;;AAEN;;;;;;GAMG","sourcesContent":[".Footer{\n    /* position : absolute; */\n    /* bottom : 0; */\n    height : 25px;\n    margin-top : 40px;\n    align-content: center;\n    background-color: rgba(53, 49, 159, 0.9);\n    color: rgba(0, 0, 0, 0.8);\n\n    background-image: none;\n    background-repeat: repeat;\n    background-attachment: scroll;\n    background-position: 0% 0%;\n\n    position: fixed;\n    bottom: 0pt;\n    left: 0pt;\n    \n}\n\n.Footer_contents {\n\n    height:40px; \n    width: 1000px; \n    margin:auto;\n\n}\n\n/* .footer {\n\n    height:24px; // Replace with the height your footer should be\n    width: 100%; // Don't change\n    background-image: none;\n    background-repeat: repeat;\n    background-attachment: scroll;\n    background-position: 0% 0%;\n    position: fixed;\n    bottom: 0pt;\n    left: 0pt;\n\n}    */\n\n/* .footer_contents {\n\n    height:24px; // Replace with the height your footer should be\n    width: 1000px; // Visible width of footer\n    margin:auto;\n\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
