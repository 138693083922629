// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-button{
    /* border: 3px solid crimson; */
    margin-bottom: 15px;
}

.left-panel{
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Content/Content.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".nav-button{\n    /* border: 3px solid crimson; */\n    margin-bottom: 15px;\n}\n\n.left-panel{\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
