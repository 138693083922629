import React from "react";
import NavHeader from "../NavHeader/NavHeader";

function AboutPage(){
    return(
        <>
            <NavHeader />
            <h2>This is sample food reciepie app.</h2>
        </>
    )
}

export default AboutPage;