import React from "react";
import NavHeader from "../NavHeader/NavHeader";

function HotDealsPage(){
    return(
        <>
            <NavHeader />
            <h2>This is hot deals pages.</h2>
        </>
    )
}

export default HotDealsPage;